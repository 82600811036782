import * as React from "react";

const HeaderTitle = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget " style={{ height: "200px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1536173459772201" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget full-width" data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1536173467547207" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1536173467547207_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h1 className="headlines" style={{ fontWeight: "bold", fontFamily: "BrutalType, sans-serif" }}>{data.title}</h1></span></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default HeaderTitle;