import { useStaticQuery, graphql } from "gatsby"

export const useContactQuery = () => {


    const data = useStaticQuery(graphql`
    query ContactQuery {
        wpPage(title: {eq: "CONTACT"}) {
          ACF_ContactPage {

            herosection {
                title
            }

            content {
                text
                map
                form
            }

            contactMetaData {
                metaTitle
                metaDescription
            }
        
        }
        }
    }     
    `)
    return data.wpPage.ACF_ContactPage;
}