import * as React from "react";
import Layout from "../components/global/Layout";
import HeaderTitle from "../components/contact/HeaderTitle";
import Body from "../components/contact/Body";
import { useContactQuery } from "../hooks/useContactQuery";
import Helmet from "react-helmet";

const ContactPage = () => {
    const data = useContactQuery();
    return(
        <Layout>
            <HeaderTitle data={data.herosection} />
            <Body data= {data.content} />
            <Helmet>
                <title>{data.contactMetaData.metaTitle}</title>
                <meta name="description" content={data.contactMetaData.metaDescription} />
            </Helmet>
        </Layout>
    );
};

export default ContactPage;